import React, { Component } from "react";
import Slider from "react-slick";
import { MDBProgress } from "mdbreact";
import { Header } from "./layout/Header";
import { CarouselPage } from "./banner/Home1_banner";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const images = [];

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    const { photoIndex, isOpen } = this.state;
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="site-main">
        <Header />
        {/* home banner */}
        <CarouselPage />
        {/* home banner end*/}
        {/* service-section */}
        <section className="ttm-row service-section position-relative z-1 clearfix">
  <div className="container-xl">
    <div className="row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey">
      <div className="col-md-4">
        <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100">
          <div className="featured-icon">
            <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
              <i className="flaticon flaticon-system" />
            </div>
          </div>
          <div className="featured-content">
            <div className="featured-title">
              <h5>Trusted Services</h5>
            </div>
            <div className="featured-desc">
              <p>Providing reliable solutions to meet our customers' needs.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white h-100">
          <div className="featured-icon">
            <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md">
              <i className="flaticon flaticon-clock" />
            </div>
          </div>
          <div className="featured-content">
            <div className="featured-title">
              <h5>24/7 Support</h5>
            </div>
            <div className="featured-desc">
              <p>Available around the clock to assist with all your needs.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100">
          <div className="featured-icon">
            <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
              <i className="flaticon flaticon-developer" />
            </div>
          </div>
          <div className="featured-content">
            <div className="featured-title">
              <h5>Expert &amp; Professional</h5>
            </div>
            <div className="featured-desc">
              <p>Delivering expertise and professionalism in every project.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
        {/* service-section end */}
        {/* aboutus-section */}
        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>About Us</h5>
                    <h2 className="title">
                      Delivering IT Solutions with 
                      <span> 10+ Years of Experience</span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      At EOJ Group, we are passionate about leveraging
                      technology to drive business growth. With over two decades
                      of experience, we specialize in delivering customized IT
                      solutions that meet the unique needs of our clients.
                    </p>
                    <p>
                      Our team of experts is dedicated to providing
                      comprehensive services ranging from web and mobile
                      application development to cloud services, cybersecurity,
                      and business process automation.
                    </p>
                  </div>
                </div>
                {/* section title end */}
                {/* row */}
                <div className="row no-gutters mt-4">
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Web and Mobile Application Development
                        </span>
                      </li>
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Custom Software Solutions
                        </span>
                      </li>
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          API Development and Integration
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Cloud Services and DevOps
                        </span>
                      </li>
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Cybersecurity and Compliance
                        </span>
                      </li>
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Business Process Automation
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* row END */}
                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-4" />
                </div>
                {/* separator */}
                <div className="fs-16 ttm-textcolor-darkgrey">
                  <span>Call us with any questions: </span>
                  <strong className="fs-18">
                    <a href="tel:6504729752"> 650-472-9752</a>
                  </strong>
                </div>
              </div>
              <div className="col-lg-6 res-991-mt-40 res-991-text-center">
                <div className="position-relative res-991-mt-30">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper">
                    <img
                      className="img-fluid"
                      src="images/single-img-seven.png"
                      title="single-img-one"
                      alt="single-img-one"
                    />
                  </div>
                  {/* ttm_single_image-wrapper end */}
                  {/* featured-icon-box */}
                  {/* featured-icon-box end */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* aboutus-section end */}
        {/* history-section */}
        <section className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                {/* section title */}
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h5>Our Services</h5>
                    <h2 className="title">
                      We run all kinds of services in form of{" "}
                      <span>Information &amp; Technologies</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            <Slider
              className="row slick_slider ttm-boxes-spacing-30px"
              {...slick_slider}
            >
              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  {/* ttm-history-box-wrapper  */}
                  <div className="ttm-history-box-icon-wrapper">
                    {/* ttm-history-box-icon-wrapper  */}
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-business-and-finance" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  {/* ttm-history-box-border  */}
                  <div className="ttm-history-box-details ttm-bgcolor-white">
                    <div className="ttm-historybox-title">
                      <h5>IT Consultancy</h5>
                    </div>
                    {/* historybox-title  */}
                    <div className="ttm-historybox-description">
                      {/* description  */}
                      Expertise in IT consultancy for the IT companies, for
                      their different working areas Web-app related.
                    </div>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  {/* ttm-history-box-wrapper  */}
                  <div className="ttm-history-box-icon-wrapper">
                    {/* ttm-history-box-icon-wrapper  */}
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-computer" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  {/* ttm-history-box-border  */}
                  <div className="ttm-history-box-details ttm-bgcolor-white">
                    <div className="ttm-historybox-title">
                      <h5>IT Management</h5>
                    </div>
                    {/* historybox-title  */}
                    <div className="ttm-historybox-description">
                      {/* description  */}
                      We provide all kind of IT management services of in the
                      according to on their organization prioritie.
                    </div>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  {/* ttm-history-box-wrapper  */}
                  <div className="ttm-history-box-icon-wrapper">
                    {/* ttm-history-box-icon-wrapper  */}
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-data" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  {/* ttm-history-box-border  */}
                  <div className="ttm-history-box-details ttm-bgcolor-white">
                    <div className="ttm-historybox-title">
                      <h5>Product Design</h5>
                    </div>
                    {/* historybox-title  */}
                    <div className="ttm-historybox-description">
                      {/* description  */}
                      Our firm is expert to create an efficient for user
                      interface that makes design user interaction lively.
                    </div>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  {/* ttm-history-box-wrapper  */}
                  <div className="ttm-history-box-icon-wrapper">
                    {/* ttm-history-box-icon-wrapper  */}
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-global-1" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  {/* ttm-history-box-border  */}
                  <div className="ttm-history-box-details ttm-bgcolor-white">
                    <div className="ttm-historybox-title">
                      <h5>Cloud Services</h5>
                    </div>
                    {/* historybox-title  */}
                    <div className="ttm-historybox-description">
                      {/* description  */}
                      We develop, migrate and work on web applications to ensure
                      that they run on capably on clouds.
                    </div>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  {/* ttm-history-box-wrapper  */}
                  <div className="ttm-history-box-icon-wrapper">
                    {/* ttm-history-box-icon-wrapper  */}
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-business-and-finance" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  {/* ttm-history-box-border  */}
                  <div className="ttm-history-box-details ttm-bgcolor-white">
                    <div className="ttm-historybox-title">
                      <h5>IT Consultancy</h5>
                    </div>
                    {/* historybox-title  */}
                    <div className="ttm-historybox-description">
                      {/* description  */}
                      Expertise in IT consultancy for the IT companies, for
                      their different working areas Web-app related.
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section>
        {/* history-section end*/}
        {/* zero-padding-section */}
        <section className="ttm-row zero-padding-section mt_80 res-991-mt-0 clearfix">
          <div className="container-xl">
            <div className="row no-gutters">
              <div className="col-lg-6">
                {/* col-bg-img-one */}
                <div className="col-bg-img-one ttm-bg ttm-col-bgimage-yes ttm-left-span res-991-h-auto">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                    <div className="ttm-bg-layer-inner" />
                  </div>
                </div>
                {/* Responsive View image */}
                <img
                  src="images/single-img-eight.jpg"
                  className="ttm-equal-height-image img-fluid"
                  alt="col-bgimage-1"
                />
              </div>
              <div className="col-lg-6 z-9">
                <div className="ttm-bgcolor-skincolor ttm-textcolor-white position-relative ttm-right-span">
                  <div className="spacing-1">
                    {/* section title */}
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h5>Why EOJ Group</h5>
                        <h2 className="title">
                          We deal with the aspects of professional{" "}
                          <span>IT Services</span>
                        </h2>
                      </div>
                      <div className="title-desc">
                        <p>
                          Our IT services converge business and technology
                          experts to help to manage business processes of all
                          categories.
                        </p>
                      </div>
                    </div>
                    {/* section title end */}
                    <div className="row mt-40">
                      <div className="col-12">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box style2 left-icon icon-align-top">
                          <div className="featured-icon">
                            {/* featured-icon */}
                            <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-process" />
                            </div>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              {/* featured title */}
                              <h5>Product Designs</h5>
                            </div>
                            <div className="featured-desc">
                              {/* featured desc */}
                              <p>
                                Our firm is expert to create an efficient user
                                interface that makes user interaction is lively.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-12">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box style2 left-icon icon-align-top">
                          <div className="featured-icon">
                            {/* featured-icon */}
                            <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-system" />
                            </div>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              {/* featured title */}
                              <h5>Big Data &amp; Analytics</h5>
                            </div>
                            <div className="featured-desc">
                              {/* featured desc */}
                              <p>
                                Statistical analysis to help an organization to
                                gain insights from large information web sets.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-12">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box style2 left-icon icon-align-top">
                          <div className="featured-icon">
                            {/* featured-icon */}
                            <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-data" />
                            </div>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              {/* featured title */}
                              <h5>Maintain App Data</h5>
                            </div>
                            <div className="featured-desc">
                              {/* featured desc */}
                              <p>
                                We Create the optimal platform to develop and
                                run digital applications for our clients app.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* zero-padding-section end */}
        {/* process-section */}
        <section className="ttm-row process-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h5>how it works</h5>
                    <h2 className="title">
                      Check Out Our Work <span>Process</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-processbox-wrapper">
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-one"
                        title="step-one"
                        src="images/step-one.jpg"
                      />
                      <div className="process-num">
                        <span className="number">01</span>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Choose Your Service</h5>
                      </div>
                      <div className="ttm-box-description">
                        Select the service that best aligns with your business
                        needs.
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-two"
                        title="step-two"
                        src="images/step-two.jpg"
                      />
                      <div className="process-num">
                        <span className="number">02</span>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Schedule a Consultation</h5>
                      </div>
                      <div className="ttm-box-description">
                        Book a meeting with our experts to discuss your
                        requirements in detail.
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-three"
                        title="step-three"
                        src="images/step-three.jpg"
                      />
                      <div className="process-num">
                        <span className="number">03</span>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Receive a Customized Plan</h5>
                      </div>
                      <div className="ttm-box-description">
                        We’ll develop a tailored plan to meet your specific
                        business objectives.
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center res-991-mb-0">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-four"
                        title="step-four"
                        src="images/step-four.jpg"
                      />
                      <div className="process-num">
                        <span className="number">04</span>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Implementation & Success</h5>
                      </div>
                      <div className="ttm-box-description">
                        Our team works closely with you to implement the plan
                        and achieve your goals.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* process-section end */}
      </div>
    );
  }
}

export default Home;
