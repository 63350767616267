import React from 'react';

const Telecommunication = () => {
  return (
    <div className="ttm-row clearfix">
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title with-desc text-center clearfix">
              <div className="title-header">
                <h5>Our Expertise in Telecommunication</h5>
                <h2 className="title">Tailored IT Solutions for the <span>Telecommunication Industry</span></h2>
              </div>
              <div className="title-desc">
                <p>In the ever-evolving telecommunication industry, staying ahead requires cutting-edge technology and seamless connectivity. We offer IT solutions that enhance network efficiency, improve customer experience, and drive innovation in your operations.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Network Infrastructure Management</h4>
                    <p>We provide comprehensive solutions for managing and optimizing your network infrastructure, ensuring high availability, reliability, and scalability to meet the growing demands of your customers.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Cloud Solutions for Telecom</h4>
                    <p>Our cloud solutions enable telecommunications companies to leverage scalable and flexible IT resources, reducing costs and improving service delivery while maintaining high levels of security.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Customer Relationship Management (CRM)</h4>
                    <p>We implement CRM systems that help telecom companies manage customer interactions effectively, improving satisfaction, loyalty, and service personalization.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Data Analytics & Reporting</h4>
                    <p>Our data analytics solutions provide telecom companies with insights into customer behavior, network performance, and market trends, enabling data-driven decisions that drive business growth.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Cybersecurity Solutions</h4>
                    <p>We offer robust cybersecurity solutions that protect your network and data from threats, ensuring compliance with industry regulations and safeguarding your customers' information.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Telecom Billing Systems</h4>
                    <p>Our telecom billing systems streamline billing processes, reduce errors, and provide accurate, real-time billing information, helping you improve revenue management and customer satisfaction.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.col-lg-12 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </div>
  );
};

export default Telecommunication;