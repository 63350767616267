import React, { Component } from 'react';
import Header from '../layout/Header';

export class Blog_top_image extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            postsPerPage: 6
        };
    }

    handleClick = (event) => {
        this.setState({
            currentPage: Number(event.target.id)
        });
    }

    render() {
        const { currentPage, postsPerPage } = this.state;

        const blogPosts = [
            {
                title: "The Future of IT Consulting: Trends to Watch",
                link: "/future-it-consulting-trends",
                summary: "Explore the latest trends in IT consulting that are shaping the future of the industry."
            },
            {
                title: "How Cloud Computing is Transforming IT Services",
                link: "/how-cloud-computing-is-transforming-it-services",
                summary: "Learn how cloud computing is revolutionizing the delivery of IT services."
            },
            {
                title: "Best Practices for IT Infrastructure Management",
                link: "/best-practices-it-infrastructure-management",
                summary: "Discover best practices for managing IT infrastructure effectively."
            },
            {
                title: "Enhancing Cybersecurity in the Digital Age",
                link: "/enhancing-cybersecurity-digital-age",
                summary: "Find out how to bolster your cybersecurity defenses in today's digital landscape."
            },
            {
                title: "The Role of AI in Modern IT Consulting",
                link: "/role-of-ai-in-modern-it-consulting",
                summary: "Understand how AI is transforming the way IT consultants operate."
            },
            {
                title: "Leveraging Big Data for Business Intelligence",
                link: "/leveraging-big-data-for-business-intelligence",
                summary: "Learn how big data is being used to drive business intelligence."
            },
            {
                title: "Why IT Consulting is Essential for Business Growth",
                link: "/why-it-consulting-is-essential-for-business-growth",
                summary: "Explore why IT consulting is a critical component for growing your business."
            },
            {
                title: "Implementing Digital Transformation Successfully",
                link: "/implementing-digital-transformation-successfully",
                summary: "Learn the key strategies for successful digital transformation."
            },
            {
                title: "Managing IT Projects with Agile Methodologies",
                link: "/managing-it-projects-with-agile-methodologies",
                summary: "Discover how agile methodologies can improve IT project management."
            },
            {
                title: "The Importance of IT Compliance and Audits",
                link: "/importance-it-compliance-audits",
                summary: "Understand the importance of IT compliance and regular audits."
            },
            {
                title: "Building a Resilient IT Strategy for the Future",
                link: "/building-resilient-it-strategy",
                summary: "Learn how to create an IT strategy that can withstand future challenges."
            },
            {
                title: "Emerging Technologies in IT Consulting",
                link: "/emerging-technologies-it-consulting",
                summary: "Explore the emerging technologies that are reshaping IT consulting."
            }
        ];

        // Logic for displaying current posts
        const indexOfLastPost = currentPage * postsPerPage;
        const indexOfFirstPost = indexOfLastPost - postsPerPage;
        const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);

        // Logic for pagination buttons
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(blogPosts.length / postsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <span
                    key={number}
                    id={number}
                    onClick={this.handleClick}
                    className={currentPage === number ? "page-numbers current" : "page-numbers"}
                >
                    {number}
                </span>
            );
        });

        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title-box text-center">
                                    <div className="page-title-heading">
                                        <h1 className="title">Blog</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="ttm-row grid-section ttm-bgcolor-grey clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                            {currentPosts.map((post, index) => (
                                <div className="col-lg-4 col-md-6" key={index}>
                                    <div className="featured-imagebox featured-imagebox-blog mb-30">
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h5><a href={process.env.PUBLIC_URL + post.link}>{post.title}</a></h5>
                                            </div>
                                            <div className="featured-desc">
                                                <p>{post.summary}</p>
                                            </div>
                                            <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" href={process.env.PUBLIC_URL + post.link}>Read More <i className="ti ti-angle-double-right" /></a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>{/* row end */}
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="ttm-pagination">
                                    {renderPageNumbers}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Blog_top_image;