import React, { Component } from "react";

export class Footer extends Component {
  render() {
    return (
      <footer className="footer widget-footer clearfix">
        {this.props.children}

        <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white py-4">
          <div className="container-xl">
            <div className="row align-items-center text-center text-lg-left">
              {/* First Column: Copyright */}
              <div className="col-lg-4 col-md-12 mb-3 mb-lg-0">
                <span className="d-block d-lg-inline">Copyright © 2024 EOJ Group LLC</span>
              </div>

              {/* Second Column: Newsletter Signup */}
              <div className="col-lg-4 col-md-12 mb-3 mb-lg-0">
                <form
                  id="subscribe-form"
                  className="newsletter-form mt-3 mt-lg-0"
                  method="post"
                  action="#"
                  data-mailchimp="true"
                >
                  <div
                    className="d-flex justify-content-center justify-content-lg-start"
                    id="subscribe-content"
                  >
                    <i className="fa fa-envelope mr-2" />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address.."
                      required
                      className="form-control"
                    />
                    <button type="submit" className="btn btn-outline-light ml-2">
                      Subscribe
                    </button>
                  </div>
                  <div id="subscribe-msg" />
                </form>
              </div>

              {/* Third Column: LinkedIn Icon */}
              <div className="col-lg-4 col-md-12">
                <a
                  href="https://www.linkedin.com/company/eoj-group/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-block d-lg-inline-block mx-auto mx-lg-0"
                  style={{ width: "100px" }}
                >
                  <img
                    src="images/linkedin.png"
                    alt="LinkedIn"
                    className="img-fluid"
                    style={{
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}