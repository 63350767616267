import React from 'react';

const ECommerceRetail = () => {
  return (
    <div className="ttm-row clearfix">
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title with-desc text-center clearfix">
              <div className="title-header">
                <h5>Our Expertise in E-Commerce & Retail</h5>
                <h2 className="title">Tailored IT Solutions for <span>E-Commerce & Retail</span></h2>
              </div>
              <div className="title-desc">
                <p>In the fast-paced world of e-commerce and retail, staying ahead of the competition requires cutting-edge technology and seamless customer experiences. We offer IT solutions that drive sales, enhance customer loyalty, and optimize your operations.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>E-Commerce Platform Development</h4>
                    <p>We develop robust e-commerce platforms tailored to your business needs, ensuring seamless user experiences, secure transactions, and scalable solutions that grow with your business.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Customer Relationship Management (CRM)</h4>
                    <p>Our CRM solutions enable you to manage customer interactions effectively, improving satisfaction and loyalty by providing personalized experiences and efficient communication.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Inventory Management Systems</h4>
                    <p>We implement inventory management systems that provide real-time tracking, automated reordering, and detailed analytics, helping you optimize your stock levels and reduce costs.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Mobile Commerce Solutions</h4>
                    <p>Our mobile commerce solutions ensure that your customers can shop anytime, anywhere, with fully optimized mobile experiences that drive conversions and enhance brand loyalty.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Payment Gateway Integration</h4>
                    <p>We integrate secure and reliable payment gateways that support multiple currencies and payment methods, ensuring smooth and secure transactions for your customers.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Data Analytics & Reporting</h4>
                    <p>Our data analytics solutions provide valuable insights into customer behavior, sales trends, and operational performance, enabling you to make informed decisions that drive business growth.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.col-lg-12 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </div>
  );
};

export default ECommerceRetail;