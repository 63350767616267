import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import Header from "../layout/Header";

export const Contact_02 = () => {
  const form = useRef();
  const [formStatus, setFormStatus] = useState(""); // Status message
  const [recaptchaToken, setRecaptchaToken] = useState(null); // Store reCAPTCHA token
  const [isSubmitting, setIsSubmitting] = useState(false); // To manage form submission state
  const [formVisible, setFormVisible] = useState(true); // Manage form visibility

  const onReCAPTCHAChange = (token) => {
    setRecaptchaToken(token);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Reset the form status before sending
    setFormStatus("");
    setIsSubmitting(true);

    // Check if all required fields are filled and reCAPTCHA is completed
    if (!form.current.user_name.value || !form.current.user_email.value || !form.current.message.value || !recaptchaToken) {
      setFormStatus("Please fill out all required fields and complete the reCAPTCHA.");
      setIsSubmitting(false);
      return;
    }

    emailjs
      .sendForm(
        "service_nfqe17j",        // Service ID
        "template_a69g8xr",       // Template ID
        form.current,             // The form ref
        "CFbukpNwW2ehc-khE"       // User ID
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          setFormStatus("Your message has been sent successfully!");
          setFormVisible(false); // Hide the form after successful submission
          
          // Check if form.current is not null before resetting
          if (form.current) {
            form.current.reset(); // Reset the form after successful submission
          }

          setRecaptchaToken(null); // Reset reCAPTCHA
          setTimeout(() => {
            setFormStatus(""); // Clear the message after a few seconds
            setFormVisible(true); // Show the form again (if you want to reset the state)
          }, 10000); // Adjust the delay as needed
        },
        (error) => {
          console.log("FAILED...", error.text);
          setFormStatus("Failed to send your message. Please try again later.");
          setIsSubmitting(false);
        }
      );
  };

  return (
    <div className="site-main">
      <Header />
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">Contact Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="ttm-row contact-section clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  <h5>Contact Us</h5>
                  <h2 className="title">Drop us a line</h2>
                </div>
              </div>
            </div>
          </div>
          {formVisible ? (
            <form ref={form} onSubmit={sendEmail} className="row ttm-quote-form clearfix">
              <input type="text" name="honeypot" style={{ display: "none" }} />
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <input
                    name="user_name"
                    type="text"
                    className="form-control bg-white border"
                    placeholder="Full Name*"
                    required
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <input
                    name="user_phone"
                    type="text"
                    placeholder="Phone Number*"
                    required
                    className="form-control bg-white border"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="form-group">
                  <input
                    name="user_email"
                    type="email"
                    placeholder="Email Address*"
                    required
                    className="form-control bg-white border"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-group">
                  <textarea
                    name="message"
                    rows={5}
                    placeholder="Write A Message..."
                    required
                    className="form-control bg-white border"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="text-center">
                  <ReCAPTCHA
                    sitekey="6LfYvDUqAAAAAKLRmD1IXpkFKWn2PCdsIYcTOwvx" // Replace with your actual site key
                    onChange={onReCAPTCHAChange}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="text-center">
                  <button
                    type="submit"
                    id="submit"
                    className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor mt-5 res-991-mt-20"
                    disabled={isSubmitting} // Disable the button while submitting
                  >
                    {isSubmitting ? "Submitting..." : "Submit Message"}
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <p className="form-status text-center">{formStatus}</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default Contact_02;