import React from 'react';

const Education = () => {
  return (
    <div className="ttm-row clearfix">
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title with-desc text-center clearfix">
              <div className="title-header">
                <h5>Our Expertise in Education</h5>
                <h2 className="title">Tailored IT Solutions for <span>Educational Institutions</span></h2>
              </div>
              <div className="title-desc">
                <p>In the education sector, technology is a powerful tool that enhances learning and streamlines administrative processes. We offer IT solutions that support educators, students, and administrators in creating a more effective and engaging educational environment.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Learning Management Systems (LMS)</h4>
                    <p>We implement and customize LMS platforms that provide a seamless online learning experience, enabling educators to deliver courses, track progress, and engage with students effectively.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Student Information Systems (SIS)</h4>
                    <p>Our SIS solutions help educational institutions manage student data, from admissions to graduation, ensuring that all information is organized, accessible, and secure.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Virtual Classroom Solutions</h4>
                    <p>We provide virtual classroom solutions that support live and interactive learning experiences, allowing educators to teach remotely while maintaining high levels of student engagement.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Educational Content Development</h4>
                    <p>Our team develops engaging educational content, including e-learning modules, interactive lessons, and multimedia resources, tailored to the specific needs of your institution.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Data Security & Compliance</h4>
                    <p>We ensure that your educational institution complies with data protection regulations and that student and faculty data is secure from unauthorized access and breaches.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Campus Management Systems</h4>
                    <p>Our campus management systems streamline administrative tasks, from facility management to scheduling and resource allocation, improving the efficiency of your institution's operations.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.col-lg-12 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </div>
  );
};

export default Education;