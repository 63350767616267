import React, { Component } from "react";
import { Menu } from "./menu";
import Mobilemenu from "./Mobile_menu";
import QuoteForm from "./QuoteForm";

export class Header extends Component {
  state = {
    showQuoteForm: false,
  };

  toggleQuoteForm = () => {
    this.setState((prevState) => ({ showQuoteForm: !prevState.showQuoteForm }));
  };

  render() {
    const { showQuoteForm } = this.state;
    return (
      <header id="masthead" className="header ttm-header-style-01">
        {/* ttm-topbar-wrapper */}
        <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <ul className="top-contact text-left">
                  <li>
                    <i className="far fa-envelope" />
                    &nbsp;&nbsp;
                    <a href="mailto:info@eojgroup.io">info@eojgroup.io</a>
                  </li>
                  <li>
                    <i className="fas fa-phone" />
                    &nbsp;&nbsp;
                    <a href="tel:650-472-9752">650-472-9752</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="topbar-right text-right d-flex justify-content-end">
                  <ul className="top-contact"></ul>
                  <div className="header-btn">
                    <button
                      className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                      onClick={this.toggleQuoteForm}
                    >
                      Get A Quote
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ttm-topbar-wrapper end */}
        {/* ttm-header-wrap */}
        <div className="ttm-header-wrap">
          <div id="navbar" className="ttm-stickable-header clearfix">
            <div className="site-header-menu">
              <div className="container-xl">
                {/* site-branding */}
                <div className="site-branding text-center">
                  <a
                    className="home-link"
                    href={process.env.PUBLIC_URL + "/"}
                    title="EOJ Group"
                    rel="home"
                  >
                    <img
                      id="logo-img"
                      className="img-fluid"
                      alt="EOJ Logo"
                      src="images/logo.png"
                    />
                  </a>
                </div>
                {/* site-branding end*/}
                {/* site-navigation */}
                <div className="site-navigation">
                  <Menu />
                  <div className="mobilemenu">
                    <Mobilemenu />
                  </div>
                </div>
                {/* site-navigation end */}
              </div>
            </div>
          </div>
        </div>
        {/* ttm-header-wrap end */}
        <QuoteForm show={showQuoteForm} onClose={this.toggleQuoteForm} />
      </header>
    );
  }
}

export default Header;