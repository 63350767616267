import React from 'react';

const Healthcare = () => {
  return (
    <div className="ttm-row clearfix">
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title with-desc text-center clearfix">
              <div className="title-header">
                <h5>Our Expertise in Healthcare</h5>
                <h2 className="title">Tailored IT Solutions for <span>Healthcare Providers</span></h2>
              </div>
              <div className="title-desc">
                <p>We understand the unique challenges of the healthcare industry, from regulatory compliance to patient data security. Our IT solutions are designed to address these challenges, helping healthcare providers deliver better care while optimizing their operations.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Electronic Health Records (EHR) Systems</h4>
                    <p>We implement and customize EHR systems that improve the accuracy and accessibility of patient data, ensuring seamless communication across departments and enhancing the quality of care.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Telemedicine Solutions</h4>
                    <p>Our telemedicine solutions enable healthcare providers to offer remote consultations, improving access to care for patients while reducing the need for in-person visits.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Healthcare Data Analytics</h4>
                    <p>We provide data analytics solutions that help healthcare organizations make informed decisions, optimize operations, and enhance patient outcomes by analyzing large volumes of data.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Patient Engagement Tools</h4>
                    <p>Our patient engagement tools help healthcare providers enhance patient satisfaction and improve health outcomes by offering personalized communication and easy access to medical information.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Regulatory Compliance Solutions</h4>
                    <p>We ensure that your IT systems comply with industry regulations like HIPAA, helping you avoid costly penalties and protecting your organization from data breaches.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Cybersecurity for Healthcare</h4>
                    <p>We provide robust cybersecurity solutions to protect sensitive patient data from threats, ensuring that your organization remains secure and compliant.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.col-lg-12 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </div>
  );
};

export default Healthcare;