import React from 'react';

const GovernmentPublicSector = () => {
  return (
    <div className="ttm-row clearfix">
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title with-desc text-center clearfix">
              <div className="title-header">
                <h5>Our Expertise in Government & Public Sector</h5>
                <h2 className="title">Tailored IT Solutions for <span>Government & Public Sector</span></h2>
              </div>
              <div className="title-desc">
                <p>In the government and public sector, technology plays a crucial role in ensuring transparency, efficiency, and security. We provide IT solutions that help government agencies and public sector organizations serve their citizens better while ensuring data integrity and compliance.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>e-Government Solutions</h4>
                    <p>We develop e-Government solutions that streamline public services, making them more accessible and efficient for citizens while ensuring transparency and accountability.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Data Security & Compliance</h4>
                    <p>Our solutions ensure that government agencies meet strict data protection regulations, safeguarding sensitive information and maintaining public trust.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Cloud Solutions for Government</h4>
                    <p>We offer secure and scalable cloud solutions that allow government agencies to enhance service delivery while reducing operational costs and improving data management.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Citizen Engagement Platforms</h4>
                    <p>Our citizen engagement platforms help governments connect with their citizens, providing channels for feedback, information dissemination, and service requests.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Infrastructure Management</h4>
                    <p>We provide solutions for managing government IT infrastructure, ensuring high availability, reliability, and performance to meet the demands of public services.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Digital Transformation Consulting</h4>
                    <p>Our consulting services help government agencies navigate digital transformation, ensuring that technology investments align with their strategic goals and deliver measurable benefits.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.col-lg-12 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </div>
  );
};

export default GovernmentPublicSector;