import React from 'react';

const Finance = () => {
  return (
    <div className="ttm-row clearfix">
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title with-desc text-center clearfix">
              <div className="title-header">
                <h5>Our Expertise in Finance</h5>
                <h2 className="title">Tailored IT Solutions for the <span>Finance Industry</span></h2>
              </div>
              <div className="title-desc">
                <p>In the highly regulated finance industry, technology is critical for ensuring compliance, security, and operational efficiency. We provide IT solutions that help financial institutions manage risk, streamline processes, and enhance customer experience.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Risk Management Solutions</h4>
                    <p>We offer advanced risk management solutions that enable financial institutions to identify, assess, and mitigate risks, ensuring regulatory compliance and protecting against financial loss.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Data Security & Compliance</h4>
                    <p>Our data security solutions are designed to protect sensitive financial data, ensuring compliance with industry regulations and safeguarding against cyber threats.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Financial Analytics & Reporting</h4>
                    <p>We provide financial analytics solutions that deliver real-time insights into financial performance, helping institutions make informed decisions and improve profitability.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Customer Relationship Management (CRM)</h4>
                    <p>Our CRM systems help financial institutions manage customer interactions effectively, improving customer satisfaction and fostering long-term relationships.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Mobile Banking Solutions</h4>
                    <p>We develop secure and user-friendly mobile banking applications that provide customers with convenient access to financial services, enhancing customer engagement and loyalty.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-box">
                  <div className="ttm-content-box">
                    <h4>Blockchain & FinTech Solutions</h4>
                    <p>Our expertise in blockchain and FinTech allows us to develop innovative solutions that streamline financial transactions, reduce costs, and improve transparency and security.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.col-lg-12 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </div>
  );
};

export default Finance;