import React, { Component } from "react";
import { MDBProgress } from "mdbreact";
import Slider from "react-slick";
import CountUp from "react-countup";
import Header from "../layout/Header";

export class Aboutus_01 extends Component {
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">About Us</h1>
                  </div>
                  {/* /.page-title-captions */}
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        {/* aboutus-section */}
        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-6">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>About Us</h5>
                    <h2 className="title">
                      We are your trusted IT partner with over 10 years of{" "}
                      <span>experience</span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      At EOJ Group, we specialize in delivering innovative and
                      tailored IT solutions that drive business success. Our
                      team of experts is dedicated to helping you achieve your
                      goals through cutting-edge technologies and strategies.
                      Whether it’s Web Development, RPA Implementation, Mobile
                      App Development, or Cyber Security, we provide end-to-end
                      services that are designed to meet the unique needs of
                      your business.
                    </p>
                    <p>
                      We believe in creating long-term partnerships with our
                      clients, offering continuous support and improvements to
                      ensure your success in an ever-evolving digital landscape.
                    </p>
                  </div>
                </div>
                {/* section title end */}
                {/* row */}
                <div className="row no-gutters mt-4 mb-4">
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          24/7 Support and Maintenance
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Web and Mobile Application Development
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Custom Software Solutions
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          API Development and Integration
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Cloud Services and DevOps
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Cyber Security and Compliance
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Business Process Automation
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Data Analysis and Visualization
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* row END*/}
                <p className="mb-2">
                  Our mission is to empower businesses across industries by
                  providing reliable, innovative, and scalable IT solutions that
                  enhance efficiency and drive growth.
                </p>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src="images/single-img-seven.png"
                      title="single-img-seven"
                      alt="single-img-seven"
                    />
                  </div>
                  {/* ttm_single_image-wrapper end */}
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* aboutus-section end*/}
        {/* progress-bar-section */}
        <section className="ttm-row zero-padding-section res-991-p-0 ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row no-gutters">
              <div className="col-md-12">
                <div className="ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-left-span spacing-7 position-relative z-1">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                    <div className="ttm-bg-layer-inner" />
                  </div>
                  <div className="layer-content">
                    <div className="row">
                      <div className="col-lg-6">
                        {/* ttm_single_image-wrapper */}
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src="images/single-img-eight.jpg"
                            title="single-img-eight"
                            alt="single-img-eight"
                          />
                        </div>
                        {/* ttm_single_image-wrapper end */}
                        <div className="about-overlay-02">
                          <h3>10+ Years of</h3>
                          <p>IT Business Experience</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="res-991-mt-30">
                          {/* section title */}
                          <div className="section-title with-desc clearfix">
                            <div className="title-header">
                              <h5>About EOJ Group</h5>
                              <h2 className="title">
                                We excel in providing professional{" "}
                                <span>IT Services</span>
                              </h2>
                            </div>
                            <div className="title-desc">
                              <p>
                                We pride ourselves on delivering exceptional IT
                                services that meet the evolving needs of our
                                clients. Our team of experienced professionals
                                is dedicated to providing top-notch solutions
                                that enhance your business operations, ensuring
                                that you stay ahead of the competition.
                              </p>
                            </div>
                          </div>
                          {/* section title end */}
                          <div className="mt-4">
                            {/* progress-bar */}
                            <div className="ttm-progress-bar">
                              <div className="progress-bar-title float-left">
                                Web Development
                              </div>
                              {/* progress-bar-title */}
                              <label className="float-right">95%</label>
                              <MDBProgress
                                className="w-100"
                                material
                                value={95}
                              ></MDBProgress>
                            </div>
                            {/* progress-bar END */}
                            {/* progress-bar */}
                            <div className="ttm-progress-bar">
                              <div className="progress-bar-title float-left">
                                RPA Implementation and Customization
                              </div>
                              {/* progress-bar-title */}
                              <label className="float-right">95%</label>
                              <MDBProgress
                                className="w-100"
                                material
                                value={95}
                              ></MDBProgress>
                            </div>
                            {/* progress-bar END */}
                            {/* progress-bar */}
                            <div className="ttm-progress-bar">
                              <div className="progress-bar-title float-left">
                                Mobile App Development
                              </div>
                              {/* progress-bar-title */}
                              <label className="float-right">90%</label>
                              <MDBProgress
                                className="w-100"
                                material
                                value={90}
                              ></MDBProgress>
                            </div>
                            {/* progress-bar END */}
                            {/* progress-bar */}
                            <div className="ttm-progress-bar">
                              <div className="progress-bar-title float-left">
                                Cloud Services
                              </div>
                              {/* progress-bar-title */}
                              <label className="float-right">85%</label>
                              <MDBProgress
                                className="w-100"
                                material
                                value={85}
                              ></MDBProgress>
                            </div>
                            {/* progress-bar END */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* progress-bar-section end */}
        {/* fid-section */}
        <section className="ttm-row fid-section ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img10 mt_225 res-991-mt-0 clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-md-3 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-online-library" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={458} duration={30} />
                    </h4>
                    <h3 className="ttm-fid-title">Completed Projects</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
              <div className="col-md-3 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-developer" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={954} duration={30} />
                    </h4>
                    <h3 className="ttm-fid-title">Happy Clients</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
              <div className="col-md-3 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-24h" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={897} duration={30} />
                    </h4>
                    <h3 className="ttm-fid-title">Services Provided</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
              <div className="col-md-3 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-report" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={785} duration={30} />
                    </h4>
                    <h3 className="ttm-fid-title">Cloud Designs Delivered</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
            </div>
            {/* row end*/}
          </div>
        </section>
        {/* fid-section end */}
      </div>
    );
  }
}

export default Aboutus_01;
