import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Header from "../layout/Header";

export class Faq extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Frequently Asked Questions</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* page-title end*/}
        <section className="ttm-row faqs-section clearfix">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-8 offset-lg-2">
                {/* section-title */}
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h5>FAQs</h5>
                    <h2 className="title">Do you have any questions?</h2>
                  </div>
                </div>
                {/* section-title end */}
              </div>
            </div>
            {/* row end */}
            {/* row */}
            <div className="row">
              <div className="col-lg-12 res-991-mt-30">
                {/* accordian */}
                <Accordion preExpanded={["foo"]}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What services does your company offer?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        We provide a wide range of IT consulting services,
                        including Web Development and Design, RPA Implementation
                        and Customization, Mobile App Development, E-Commerce
                        Development, API Development and 3rd Party Integration,
                        Custom Software Development, SaaS Solutions, Automated
                        Testing and QA Services, Business Process Automation,
                        CRM Implementation and Customization, Cyber Security
                        Services, DevOps, CICD and Cloud Services, Database
                        Management and Optimization, Data Analysis and
                        Visualization, and IT Support and Maintenance.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Which industries do you serve?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        We serve a diverse set of industries, including
                        Healthcare, Finance, E-Commerce & Retail, Education,
                        Telecommunication, and Government & Public Sector. Our
                        solutions are tailored to meet the specific needs and
                        challenges of each industry, ensuring optimal outcomes
                        for our clients.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How can I get started with your services?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Getting started is easy! You can reach out to us through
                        our contact form on the website or call us directly at
                        650-472-9752. We will schedule an initial consultation
                        to understand your needs and discuss how we can help you
                        achieve your business goals.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Do you provide customized solutions?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, we specialize in creating customized solutions that
                        are tailored to meet the unique needs of your business.
                        Whether you need a custom software application, a
                        tailored CRM system, or a unique website design, our
                        team is equipped to deliver solutions that align with
                        your business objectives.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is your approach to project management?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        We follow a structured project management approach that
                        includes initial consultation, planning, design,
                        development, testing, and deployment. We maintain clear
                        communication with our clients throughout the project
                        lifecycle to ensure that the final product meets their
                        expectations. Our goal is to deliver high-quality,
                        on-time, and on-budget solutions.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How do you ensure the security of your solutions?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Security is a top priority for us. We implement
                        industry-standard security practices and protocols in
                        all our solutions. From secure coding practices to
                        regular security audits and updates, we ensure that your
                        systems are protected against potential threats. Our
                        Cyber Security Services are specifically designed to
                        safeguard your digital assets from cyber threats.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What technologies do you specialize in?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        We specialize in a wide range of technologies, including
                        web and mobile development frameworks, cloud platforms,
                        RPA tools, database management systems, API development,
                        cybersecurity tools, and more. Our team stays updated
                        with the latest technologies to provide cutting-edge
                        solutions to our clients.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How can I get a quote for your services?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        To get a quote, you can contact us through our website
                        or by phone. We will discuss your project requirements
                        in detail and provide you with a customized quote based
                        on your needs. We aim to offer competitive pricing
                        without compromising on quality.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
            {/* row end*/}
            {/* row */}
            <div className="row mt-70 res-991-mt-30">
              <div className="col-lg-4 res-991-mb-20">
                {/*  featured-icon-box */}
                <div className="featured-icon-box left-icon style7 icon-align-top">
                  <div className="featured-icon">
                    {/*  featured-icon */}
                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-md">
                      <i className="flaticon flaticon-system" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>Make It Simple</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p>
                        We believe in straightforward solutions that deliver
                        results. Our approach focuses on clarity and efficiency,
                        ensuring that every step is easy to understand and
                        implement.
                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div className="col-lg-4 res-991-mb-20">
                {/*  featured-icon-box */}
                <div className="featured-icon-box left-icon style7 icon-align-top">
                  <div className="featured-icon">
                    {/*  featured-icon */}
                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-md">
                      <i className="flaticon flaticon-data" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>Unique Strategy</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p>
                        Our tailored strategies are designed to meet the
                        specific needs of your business. We combine industry
                        insights with innovative thinking to create a roadmap
                        for success.
                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div className="col-lg-4">
                {/*  featured-icon-box */}
                <div className="featured-icon-box left-icon style7 icon-align-top">
                  <div className="featured-icon">
                    {/*  featured-icon */}
                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-md">
                      <i className="flaticon flaticon-analytics" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>Unique Planning</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p>
                        We plan with precision and foresight, considering all
                        aspects of your business goals. Our meticulous planning
                        process ensures that every project is executed
                        flawlessly from start to finish.
                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
            </div>
            {/* row end*/}
          </div>
        </section>
      </div>
    );
  }
}

export default Faq;