import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import "./QuoteForm.css";

const QuoteForm = ({ show, onClose }) => {
  const form = useRef();
  const [howDidYouHear, setHowDidYouHear] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [formData, setFormData] = useState({
    user_name: "",
    user_phone: "",
    user_company: "",
    user_email: "",
    project_description: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [formVisible, setFormVisible] = useState(true); // Control form visibility

  const validateForm = () => {
    let errors = {};
    if (!formData.user_name) errors.user_name = "Name is required";
    if (!formData.user_phone) errors.user_phone = "Phone number is required";
    if (!formData.user_company) errors.user_company = "Company is required";
    if (!formData.user_email) {
      errors.user_email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.user_email)) {
      errors.user_email = "Email is invalid";
    }
    if (!formData.project_description) errors.project_description = "Project description is required";
    if (!howDidYouHear) errors.howDidYouHear = "Please select how you heard about us";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    if (recaptchaToken) {
      emailjs
        .sendForm("service_nfqe17j", "template_twngvcm", form.current, "CFbukpNwW2ehc-khE")
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            setSuccessMessage("Quote was successfully submitted!");
            setFormVisible(false); // Hide the form

            setTimeout(() => {
              setSuccessMessage(""); // Clear the message after a few seconds
              setFormVisible(true); // Show the form again
              onClose(); // Optionally close the form modal
            },10000); // Adjust the delay as needed
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    } else {
      console.error("reCAPTCHA not completed");
      setFormErrors({ recaptcha: "Please complete the reCAPTCHA" });
    }
  };

  return (
    <div className={`quote-form-container ${show ? "show" : ""}`}>
      <div className="quote-form-overlay" onClick={onClose}></div>
      <div className="quote-form">
        <h2>Get A Quote</h2>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {formVisible && (
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-group">
              <input
                type="text"
                name="user_name"
                placeholder="Your Name"
                value={formData.user_name}
                onChange={handleChange}
                required
              />
              {formErrors.user_name && <p className="error">{formErrors.user_name}</p>}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="user_phone"
                placeholder="Phone Number"
                value={formData.user_phone}
                onChange={handleChange}
                required
              />
              {formErrors.user_phone && <p className="error">{formErrors.user_phone}</p>}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="user_company"
                placeholder="Your Company"
                value={formData.user_company}
                onChange={handleChange}
                required
              />
              {formErrors.user_company && <p className="error">{formErrors.user_company}</p>}
            </div>
            <div className="form-group">
              <input
                type="email"
                name="user_email"
                placeholder="Your Email"
                value={formData.user_email}
                onChange={handleChange}
                required
              />
              {formErrors.user_email && <p className="error">{formErrors.user_email}</p>}
            </div>
            <div className="form-group">
              <select
                name="user_hear_about"
                value={howDidYouHear}
                onChange={(e) => setHowDidYouHear(e.target.value)}
                required
              >
                <option value="" disabled>
                  How Did You Hear About Us?
                </option>
                <option value="internet">Internet</option>
                <option value="google">Google</option>
                <option value="website">Website</option>
                <option value="linkedin">LinkedIn</option>
                <option value="current_customer">Current Customer</option>
                <option value="referral">Referral</option>
                <option value="other">Other</option>
              </select>
              {formErrors.howDidYouHear && <p className="error">{formErrors.howDidYouHear}</p>}
            </div>
            <div className="form-group">
              <textarea
                name="project_description"
                placeholder="Project Description"
                rows="6"
                value={formData.project_description}
                onChange={handleChange}
                required
              ></textarea>
              {formErrors.project_description && <p className="error">{formErrors.project_description}</p>}
            </div>
            <div className="form-group">
              <ReCAPTCHA
                sitekey="6LfYvDUqAAAAAKLRmD1IXpkFKWn2PCdsIYcTOwvx" // Replace with your actual site key
                onChange={handleRecaptchaChange}
              />
              {formErrors.recaptcha && <p className="error">{formErrors.recaptcha}</p>}
            </div>
            <button type="submit">Submit</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default QuoteForm;