import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Header from '../layout/Header';

// Import the industry-specific components using relative paths from src
import Healthcare from '../project/industries/Healthcare';
import Finance from '../project/industries/Finance';
import ECommerceRetail from '../project/industries/ECommerceRetail';
import Education from '../project/industries/Education';
import Telecommunication from '../project/industries/Telecommunication';
import GovernmentPublicSector from '../project/industries/GovernmentPublicSector';

export class Project_style1 extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Industries</h1>
                  </div>
                  {/* /.page-title-captions */}
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <div className="ttm-row tab-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-12">
                <div className="ttm-tabs text-center ttm-tab-style-classic style2">
                  <Tabs>
                    <TabList className="text-uppercase mb-4">
                      <Tab><span>Healthcare</span></Tab>
                      <Tab><span>Finance</span></Tab>
                      <Tab><span>E-Commerce & Retail</span></Tab>
                      <Tab><span>Education</span></Tab>
                      <Tab><span>Telecommunication</span></Tab>
                      <Tab><span>Government & Public Sector</span></Tab>
                    </TabList>
                    <div className="content-tab">
                      <TabPanel>
                        <Healthcare />
                      </TabPanel>
                      <TabPanel>
                        <Finance />
                      </TabPanel>
                      <TabPanel>
                        <ECommerceRetail />
                      </TabPanel>
                      <TabPanel>
                        <Education />
                      </TabPanel>
                      <TabPanel>
                        <Telecommunication />
                      </TabPanel>
                      <TabPanel>
                        <GovernmentPublicSector />
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Project_style1;